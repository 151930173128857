import {
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  useColorModeValue,
  Button
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaMinus, FaPlus, GrPowerReset, RiFontSize } from "react-icons/all";
import { BsArrowClockwise, BsPlusLg, BsSubtract } from "react-icons/bs";

export const useFontAdjuster = () => {
  const [fontMult, setFontMult] = React.useState(1);
  const [initialised, setInitialised] = React.useState(false);

  useEffect(() => {
    const fromLocal = localStorage.getItem("ibpi-font-adjust");
    if (fromLocal) setFontMult(parseFloat(fromLocal));
    setInitialised(true);
  }, []);

  useEffect(() => {
    if (initialised)
      localStorage.setItem("ibpi-font-adjust", fontMult.toString());
  }, [fontMult]);

  const increase = (amount: number = 0.1) => {
    setFontMult(fontMult + amount);
  };

  const decrease = (amount: number = 0.1) => {
    setFontMult(fontMult - amount);
  };

  return { fontMult, setFontMult, increase, decrease };
};

const recurseAndDo = (
  el: HTMLElement,
  func: (el: HTMLElement) => void,
  idExclude?: string
) => {
  if (idExclude == null || el.id !== idExclude) func(el);
  // @ts-ignore
  for (let child of el.children) {
    if (idExclude == null || child.id !== idExclude)
      recurseAndDo(child, func, idExclude);
  }
};

const FontAdjuster = () => {
  const bg = useColorModeValue("white", "gray.800");
  const { increase, decrease, fontMult, setFontMult } = useFontAdjuster();

  // update font sizes when multiplier changes
  useEffect(() => {
    const contentContainer = document.getElementById("content-container");
    if (contentContainer) {
      contentContainer.setAttribute("style", `zoom: ${fontMult}`);
    }
  }, [fontMult]);

  return (
    <Popover trigger="hover" placement="left">
      <PopoverTrigger>
        <IconButton
          aria-label="adjust font size"
          icon={<RiFontSize />}
          isRound
          bgColor={bg}
          size="lg"
          shadow="md"
        />
      </PopoverTrigger>
      <PopoverContent minW="unset" w="unset" rounded="lg">
        <PopoverArrow />
        <PopoverBody>
          <Flex>
            <Button
              aria-label={"reset"}
              onClick={() => setFontMult(1)}
              mr={1}
              rounded="full"
            >
              Reset
            </Button>
            <Spacer />
            <IconButton
              icon={<FaMinus />}
              aria-label="reduce"
              isRound
              mr={1}
              onClick={() => {
                decrease();
              }}
            />
            <Spacer />
            <IconButton
              icon={<FaPlus />}
              aria-label="increase"
              isRound
              onClick={() => {
                increase();
              }}
            />
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FontAdjuster;
