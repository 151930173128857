import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { MDXProvider } from "@mdx-js/react";
import { graphql, Link, navigate } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React, { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import CompleteNotification from "../components/CompleteNotification";
import FavouriteButton from "../components/FavouriteButton";
import FontAdjuster from "../components/FontAdjuster";
import Highlighter from "../components/Highlighter";
import Layout from "../components/Layout";
import LinkButton from "../components/LinkButton";
import { MDXComponents } from "../components/mdx-components/mdx-components";
import SearchComponent from "../components/SearchComponent";
import TTSButton from "../components/TTSButton";
import {
  useMarkModuleProgress,
  useModuleProgress,
  useModules,
} from "../data/modulesAPI";
import { useSaveSettings } from "../data/settingsApi";
import { useToolbox } from "../data/toolboxApi";
import { useAllCompleted } from "../hooks/useAllCompleted";

type Props = {
  data: {
    mdx: {
      id: string;
      slug: string;
      body: string;
      rawBody: string;
      frontmatter: {
        title: string;
        index: number;
        time: number;
        moduleNumber: number;
        primaryColour: string;
        secondaryColour: string;
        featuredImage: {
          childImageSharp: {
            gatsbyImageData: any;
          };
        };
      };
    };
  };
};

export default function Template({ data }: Props) {
  const [isMobile] = useMediaQuery("(max-width: 540px)");

  const { allCompleted } = useAllCompleted();

  const saveSettings = useSaveSettings();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mdx } = data;
  const [showHighlights, setShowHighlights] = useState(true); // allows highlighting to be turned on and off - not used atm but could be for a toggle at the top perhaps

  const modules = useModules();
  const currentModule = modules[mdx.frontmatter.moduleNumber - 1];

  if (!currentModule) return null;

  const topics = currentModule.topics;
  const currentTopic = topics[mdx.frontmatter.index - 1];
  const title = currentModule.title;

  const [topicCompleted, setTopicCompleted] = useState<boolean>();

  const { data: moduleProgress } = useModuleProgress(
    mdx.frontmatter.moduleNumber.toString()
  );
  const markModuleProgress = useMarkModuleProgress(
    mdx.frontmatter.moduleNumber.toString(),
    mdx.frontmatter.index,
    currentModule.topics.length
  );

  const {
    data: toolBoxData,
    loading: toolBoxLoading,
    fetchData: toolBoxRefetch,
  } = useToolbox();

  useEffect(() => {
    toolBoxRefetch();
  }, []);

  useEffect(() => {
    if (moduleProgress?.completed >= mdx.frontmatter.index) {
      setTopicCompleted(true);
    }
  }, [moduleProgress]);

  const toggleCompleted = async () => {
    //TODO: This could be more readable :(
    if (!topicCompleted) {
      await markModuleProgress.mutateAsync(!topicCompleted);
      setTopicCompleted(!topicCompleted);
    }
  };

  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data.mdx.slug) {
      saveSettings.mutateAsync(
        {
          lastTopicVisited: data.mdx.slug,
        },
        true
      );
    }
  }, [data]);

  const nextTopic = async () => {
    if (!topicCompleted) {
      await toggleCompleted();
    }

    if (
      (!allCompleted && mdx.frontmatter.index < topics.length - 1) ||
      currentModule.index !== 9 || (allCompleted && currentModule.index === 9)
    ) {
      navigate(`/${topics[mdx.frontmatter.index]?.slug}`);
    } else {
      navigate("/");
    }
  };

  const nextModule = async () => {
    if (!topicCompleted) {
      await toggleCompleted();
    }
    if (modules[mdx.frontmatter.moduleNumber]?.slug) {
      navigate(`/${modules[mdx.frontmatter.moduleNumber]?.topics[0]?.slug}`);
    } else {
      navigate("/");
    }
  };

  const bg = useColorModeValue("white", "gray.800");
  const blue = useColorModeValue("blue.400", "blue.300");

  const green = useColorModeValue("green.800", "green.300");
  const green2 = useColorModeValue("green.200", "green.700");

  const dirty = useRef<boolean>(false);

  return (
    <Layout>
      <Flex id="flex-22-s" pb={6} mb={4}>
        <Center>
          <IconButton
            aria-label="go back"
            as={Link}
            to="../"
            icon={<Icon as={FiArrowLeft} />}
            bgColor={bg}
            isRound
            size="lg"
            shadow="md"
          />
        </Center>
        <Spacer />
        <FontAdjuster />
        {isMobile ? (
          <>
            <Box ml={2} />
            <SearchComponent asInput={false} />
          </>
        ) : null}
      </Flex>
      <Box ref={bodyRef} rounded={"xl"} bg={bg} p={8} id="content-container">
        <Flex alignItems="center" gap={3} mb={6} userSelect="none">
          <Stack flex={1} spacing={1}>
            <Text fontSize={"sm"}>
              {title} •{" "}
              <chakra.span opacity={0.7}>
                {mdx.frontmatter.time} mins
              </chakra.span>
            </Text>
            <Heading as="h1" fontWeight="bold" size={"md"}>
              {mdx.frontmatter.title}
            </Heading>
          </Stack>
          <Flex alignItems="center" gap={2} id="no-resize">
            <FavouriteButton
              favouriteType="topic"
              id={mdx.slug}
              toolBoxLoading={toolBoxLoading}
              toolBoxData={toolBoxData}
              refetchToolbox={toolBoxRefetch}
            />
            <TTSButton text={mdx.rawBody} title={mdx.frontmatter.title} />
          </Flex>
        </Flex>

        <Highlighter
          moduleId={mdx.slug}
          show={showHighlights}
          toolBoxData={toolBoxData}
          toolBoxRefetch={toolBoxRefetch}
        />
        <MDXProvider components={MDXComponents}>
          <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
        </MDXProvider>

        {currentTopic && currentTopic.keywords.includes("resources")
          ? currentModule.resources.map((resource, index) => (
              <Box p={2} m={2} rounded="md" key={"Resource" + index}>
                <Flex flexDir="column" gap={1}>
                  <Flex flexDir="row">
                    <Center>
                      <Heading size="sm">{resource.title}</Heading>
                    </Center>
                    <Spacer />
                    <FavouriteButton
                      favouriteType="resource"
                      id={resource.id}
                      moreData={{
                        name: resource.title,
                        description: resource.description,
                        url: resource.url,
                      }}
                      toolBoxLoading={toolBoxLoading}
                      toolBoxData={toolBoxData}
                      refetchToolbox={toolBoxRefetch}
                    />
                  </Flex>
                  <Text>{resource.description}</Text>
                  <Flex>
                    <LinkButton url={resource.url} clear={true} />
                  </Flex>
                </Flex>
              </Box>
            ))
          : null}

        <Stack direction="row" mt={5}>
          {topicCompleted ? (
            <Button
              size="sm"
              p={"10px"}
              color={green}
              bg={green2}
              rounded="xl"
              onClick={toggleCompleted}
              isLoading={markModuleProgress.isLoading}
              alignContent="right"
              isDisabled={
                (moduleProgress?.completed ?? 0) < mdx.frontmatter.index
              }
            >
              Completed&nbsp;&nbsp;
              <Spacer />
              <Icon as={FaCheck} />
            </Button>
          ) : null}
          <Spacer />
          {mdx.frontmatter.index < topics?.length ? (
            <Button
              size="sm"
              color={bg}
              bg={blue}
              rounded="xl"
              onClick={nextTopic}
              isLoading={markModuleProgress.isLoading}
              alignContent="right"
            >
              Next Topic
            </Button>
          ) : mdx.frontmatter.index === topics?.length ? (
            <Button
              size="sm"
              color={bg}
              bg={blue}
              rounded="xl"
              onClick={onOpen}
              alignContent="right"
            >
              Complete Module
            </Button>
          ) : null}
        </Stack>
      </Box>
      <CompleteNotification
        title={
          modules[mdx.frontmatter.moduleNumber - 1]?.slug
            ? `Well done! You've finished this module "${
                modules[mdx.frontmatter.moduleNumber - 1].title
              }"`
            : "Well done! You've completed all the modules!"
        }
        subtitle="Feel free to revisit this module at any time from the modules page."
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={nextModule}
        buttonText={
          modules[mdx.frontmatter.moduleNumber]?.slug
            ? `Start "${modules[mdx.frontmatter.moduleNumber].title}"`
            : "Return Home"
        }
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      rawBody
      slug
      frontmatter {
        title
        index
        moduleNumber
        time
      }
    }
  }
`;
