import React, { useEffect } from "react";
import {
  Box,
  Button,
  Center,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { createHighlight } from "../data/toolboxApi";
import { useMutation } from "../data/util";

interface Props {
  moduleId: string;
  onSelectStart?: (elemId: string) => void;
  onSelectEnd?: () => void;
  deselectOnClick?: boolean; // default to true
  onHighlight?: () => void;
}

export const getLenOfNode = (node: any) => {
  if (node.length) return node.length;
  let len = 0;
  let found = false;
  while (!found) {
    node.childNodes.forEach((child: any) => {
      len += getLenOfNode(child);
    });
    found = true;
  }
  return len;
};

const HighlightPopover = ({
  moduleId,
  onSelectStart = (elemId) => null,
  onSelectEnd = () => null,
  deselectOnClick = true,
  onHighlight,
}: Props) => {
  const [triCol, setTriCol] = React.useState("whitesmoke");

  const [text, setText] = React.useState<string | null>(null);
  const [rect, setRect] = React.useState<DOMRect | null>(null);
  const [elem, setElem] = React.useState<string | null>(null);
  const [indexes, setIndexes] = React.useState<{
    start: number;
    end: number;
  } | null>(null);

  const [usingContextMenu, setUsingContextMenu] = React.useState(false);

  const toast = useToast();

  const isMobile =
    "ontouchstart" in document.documentElement &&
    navigator.userAgent.match(/Mobi/);

  const addHighlightMutation = useMutation(
    () => {
      if (!text || !elem || !indexes)
        throw new Error("text, elem, and indexes must be set");
      return createHighlight({
        from: moduleId,
        elemId: elem,
        text: text,
        start: indexes.start,
        end: indexes.end,
      });
    },
    {
      onSuccess: () => {
        onHighlight?.();
      },
    }
  );

  const clear = () => {
    setText(null);
    setRect(null);
    setElem(null);
    setIndexes(null);
    deselectOnClick ? window.getSelection()?.removeAllRanges() : null;
    onSelectEnd();
  };

  const findStartAndEnd = (
    anchor: Node,
    anchorOffset: number,
    focus: Node,
    focusOffset: number,
    toSearch: HTMLElement,
    start = null,
    startOffset = null,
    end = null,
    endOffset = null
  ): // @ts-ignore
  {
    start: Node | null;
    startOffset: number | null;
    end: Node | null;
    endOffset: number | null;
  } => {
    if (start && startOffset && end && endOffset)
      return { start, startOffset, end, endOffset };
    // @ts-ignore
    for (const child of toSearch.childNodes) {
      if (
        child.nodeValue === anchor.nodeValue &&
        child.nodeValue === focus.nodeValue
      ) {
        return {
          start: child,
          startOffset: anchorOffset <= focusOffset ? anchorOffset : focusOffset,
          end: focus,
          endOffset: anchorOffset <= focusOffset ? focusOffset : anchorOffset,
        };
      } else if (child.nodeValue === anchor.nodeValue) {
        return {
          start: child,
          startOffset: anchorOffset,
          end: focus,
          endOffset: focusOffset,
        };
      } else if (child.nodeValue === focus.nodeValue) {
        return {
          start: child,
          startOffset: focusOffset,
          end: anchor,
          endOffset: anchorOffset,
        };
      }
      if (child.childNodes.length > 0) {
        const found = findStartAndEnd(
          anchor,
          anchorOffset,
          focus,
          focusOffset,
          child,
          start,
          startOffset,
          end,
          endOffset
        );
        if (found) return found;
      }
    }
  };

  const findLenToNode = (
    node: Node,
    toSearch: HTMLElement,
    len = 0
  ): { found: boolean; len: number } => {
    // @ts-ignore
    for (const child of toSearch.childNodes) {
      if (child === node) return { found: true, len };
      if (child.childNodes.length > 0) {
        const res = findLenToNode(node, child, len);
        if (res.found) return res;
      }
      len += getLenOfNode(child);
    }
    return { found: false, len };
  };

  const getAbsoluteIndex = (
    selected: Selection,
    parent: HTMLElement,
    overrideFocusNode: any,
    overrideFocusOffset: any
  ) => {
    if (!selected.anchorNode || (!selected.focusNode && !overrideFocusNode))
      return null;

    const found = findStartAndEnd(
      selected.anchorNode,
      selected.anchorOffset,
      overrideFocusNode ?? selected.focusNode,
      overrideFocusOffset ?? selected.focusOffset,
      parent
    );
    // console.log("found", found);
    const { start: startNode, startOffset, end: endNode, endOffset } = found;

    if (!startNode || !endNode || startOffset === null || endOffset === null)
      return null;

    const start = findLenToNode(startNode, parent).len + startOffset;
    const end = findLenToNode(endNode, parent).len + endOffset;

    return { start: start ?? 0, end: end ?? 0 };
  };

  useEffect(() => {
    const getRectWithScrollOffset = (): DOMRect | null => {
      const selection = window.getSelection();
      if (!selection || selection.rangeCount < 1) return null;
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      return new DOMRect(
        rect.left + scrollLeft,
        rect.top + scrollTop,
        rect.width,
        rect.height
      );
    };

    const handleSelect = () => {
      const selected = window.getSelection();
      if (selected) {
        setText(selected.toString() ?? null);
        if (selected.rangeCount > 0) {
          setRect(getRectWithScrollOffset());
        }

        if (selected.toString()) {
          const parent = selected.anchorNode?.parentElement;
          let topParent = parent;
          while (
            topParent?.parentElement &&
            topParent?.parentElement?.id !== "content-container" &&
            // !topParent?.parentElement?.classList.contains("chakra-accordion__panel") &&
            !topParent?.classList.contains("chakra-accordion__panel")
          ) {
            topParent = topParent.parentElement;
          }
          if (topParent && topParent.id && parent) {
            if (topParent.tagName === "UL" || topParent.tagName === "OL") {
              onSelectStart(parent.id);
            } else {
              onSelectStart(topParent.id);
            }

            let overrideFocusNode = undefined;
            let overrideFocusOffset = undefined;
            if (
              // @ts-ignore
              selected.focusNode?.childNodes?.length < 1 &&
              selected.focusNode?.nodeName !== "#text"
            ) {
              overrideFocusNode = selected?.focusNode?.previousSibling;
              overrideFocusOffset =
                getLenOfNode(selected?.focusNode?.previousSibling) ?? 0;
            }
            setIndexes(
              getAbsoluteIndex(
                selected,
                topParent,
                overrideFocusNode,
                overrideFocusOffset
              )
            );
            setElem(topParent.id);
          }
        } else onSelectEnd();
      }
    };

    const hidePopover = () => {
      setText(null);
      setRect(null);
      setElem(null);
      setIndexes(null);
    };
    window.addEventListener("mousemove", handleSelect); // for normal select
    window.addEventListener("click", handleSelect); // for double click and clicking off selection
    window.addEventListener("touchstart", handleSelect); // for touch select
    window.addEventListener("touchmove", hidePopover); // hide popover on adjust - not emitted on android :/
    window.addEventListener("touchend", handleSelect); // for touch select
    window.addEventListener("contextmenu", () => {
      setUsingContextMenu(true);
      handleSelect();
    }); // for android selection
    window.addEventListener("touchcancel", handleSelect); // for touch select

    window.addEventListener("scroll", () => {
      const currentRect = getRectWithScrollOffset();
      if (currentRect) setRect(currentRect);
    });
  }, []);

  useEffect(() => {
    if (!text || !rect) {
      setTriCol("whitesmoke");
    }
  });

  const bg = useColorModeValue("whitesmoke", "gray.600");

  const addHighlight = async (
    e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    const res = await addHighlightMutation.mutateAsync();
    if (!res.isError) {
      clear();
      toast({
        title: "Added Highlight",
        description: "You can find this in your toolbox",
        status: "info",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  };

  if (text && rect) {
    return (
      <Box
        position="absolute"
        top={rect.bottom}
        left={rect.right - rect.width / 2}
        transform={`translateY(20%) translateX(-50%)`}
        userSelect="none"
      >
        <Center m={0} p={0}>
          <Box
            width={0}
            height={0}
            borderLeft="10px solid transparent"
            borderRight="10px solid transparent"
            borderBottom={`10px solid ${isMobile ? "whitesmoke" : triCol}`}
          />
        </Center>

        <Button
          variant="ghost"
          fontWeight="normal"
          _hover={isMobile ? {} : { bgColor: "#C1BED9" }}
          size="sm"
          bgColor={bg}
          rounded="lg"
          m={0}
          shadow="dark-lg"
          onMouseEnter={() => setTriCol("#C1BED9")}
          onMouseLeave={() => setTriCol("whitesmoke")}
          borderWidth="0px"
          onClick={(e) => {
            if (!isMobile) {
              addHighlight(e);
            }
          }}
          onTouchStart={(e) => {
            if (isMobile) {
              addHighlight(e);
            }
          }}
        >
          Create Highlight
        </Button>
      </Box>
    );
  } else return null;
};

export default HighlightPopover;
