import React, { useEffect, useRef, useState } from "react";
import HighlightPopover, { getLenOfNode } from "./HighlightPopover";
import {
  deleteHighlight,
  getHighlightsForModule,
  Highlight,
  ToolboxItem,
  useGetHighlights,
} from "../data/toolboxApi";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "../data/util";

const Highlighter = ({
  moduleId,
  show,
  toolBoxData,
  toolBoxRefetch,
}: {
  moduleId: string;
  show: boolean;
  toolBoxData: ToolboxItem[];
  toolBoxRefetch: Function;
}) => {
  const [highlights, setHighlights] = useState<Map<string, Highlight[]>>(
    new Map()
  );
  // const highlightsQuery = useQuery("highlights" + moduleId, () =>
  //   getHighlightsForModule(moduleId)
  // );

  useEffect(() => {
    if (toolBoxData.length > 0) {
      removeHighlights();
      setHighlights(
        groupByElem(findHighlightsInToolbox(toolBoxData, moduleId))
      );
    }
  }, [toolBoxData, moduleId]);

  function findHighlightsInToolbox(tb: ToolboxItem[], moduleSlug: string): any {
    if (tb.length === 0) {
      return {};
    }
    console.log("zzz 1", moduleSlug, tb);
    return tb
      .filter((item) => {
        if (item.type === "highlight") {
          console.log(item, "zzz is highlight", item.data.from, moduleSlug);
          if (item.data.from === moduleSlug) {
            return true;
          }
        }
      })
      .map((item: { data: any; itemId: any }) => {
        return { ...item.data, id: item.itemId };
      });
  }

  const recurseThroughElems = (
    elem: HTMLElement,
    specialId: string | null,
    setNorm: string,
    setSpecial: string | null
  ) => {
    if (elem.children) {
      // @ts-ignore
      for (let child of elem.children) {
        if (specialId === null || child.id !== specialId) {
          child.style.userSelect = setNorm;
          recurseThroughElems(child, specialId, setNorm, setSpecial);
        } else {
          child.style.userSelect = setSpecial;
        }
      }
    }
  };

  // disable selection outside the element since we cannot highlight across elements
  const startSelection = (elemId: string) => {
    const container = document.getElementById("content-container");
    if (container) recurseThroughElems(container, elemId, "none", "text");
  };
  // re-enable selection outside the element
  const endSelection = () => {
    const container = document.getElementById("content-container");
    if (container) recurseThroughElems(container, null, "auto", null);
  };

  const alertDialogRef = useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedHighlight, setSelectedHighlight] = useState<Highlight | null>(
    null
  );

  const toast = useToast();
  const removeHighlightMutation = useMutation(
    () => deleteHighlight(selectedHighlight),
    {
      onSuccess: () => {
        toolBoxRefetch();
      },
    }
  );

  // useEffect(() => {
  //   if (highlightsQuery.refetch && highlightsQuery.data === undefined)
  //     highlightsQuery.refetch();
  // }, [highlightsQuery.refetch]);

  const groupByElem = (highlights: Highlight[]): Map<string, Highlight[]> => {
    const highlightsByElem: Map<string, Highlight[]> = new Map();
    highlights?.forEach((highlight) => {
      if (!highlightsByElem.has(highlight.elemId)) {
        highlightsByElem.set(highlight.elemId, [highlight]);
      } else {
        highlightsByElem.get(highlight.elemId)?.push(highlight);
      }
    });
    return highlightsByElem;
  };

  // get highlights for page
  // useEffect(() => {
  //   if (highlightsQuery.data) {
  //     removeHighlights();
  //     const highlights = highlightsQuery.data;
  //     setHighlights(groupByElem(highlights));
  //   }
  // }, [moduleId, highlightsQuery.data]);

  // recursive search to find start node
  const getStart = (
    parent: HTMLElement,
    start: number,
    pos = 0
  ): { node: Node | null; offset: number } | null => {
    // @ts-ignore
    for (const child of parent.childNodes) {
      if (child.childNodes.length > 0) {
        const res = getStart(child, start, pos);
        if (res) return res;
        else pos += getLenOfNode(child);
      } else {
        const nextPos = pos + getLenOfNode(child);
        if (nextPos > start) return { node: child, offset: start - pos };
        pos = nextPos;
      }
    }
    return null;
  };

  // recursive search to find end node
  const getEnd = (
    parent: HTMLElement,
    end: number,
    pos = 0
  ): { node: Node | null; offset: number } | null => {
    // @ts-ignore
    for (const child of parent.childNodes) {
      if (child.childNodes.length > 0) {
        const res = getEnd(child, end, pos);
        if (res) return res;
        else pos += getLenOfNode(child);
      } else {
        const nextPos = pos + getLenOfNode(child);
        if (nextPos >= end) return { node: child, offset: end - pos };
        pos = nextPos;
      }
    }
    return null;
  };

  const addHighlight = (parent: HTMLElement, highlight: Highlight) => {
    if (highlight.start >= highlight.end) {
      console.error("highlight start is after highlight end", highlight);
      return;
    }
    const range = new Range();
    const { node: startNode, offset: startOffset } = getStart(
      parent,
      highlight.start
    ) ?? { startNode: null, startOffset: null };
    let { node: endNode, offset: endOffset } = getEnd(
      parent,
      highlight.end
    ) ?? { endNode: null, endOffset: null };
    if (
      !startNode ||
      !endNode ||
      startOffset === null ||
      startOffset === undefined ||
      endOffset === null ||
      endOffset === undefined
    ) {
      console.error("could not find start or end node for ", highlight);

      return;
    }
    try {
      range.setStart(startNode, startOffset);
    } catch (e) {
      console.error("error setting start of range:", e);
      return;
    }
    try {
      range.setEnd(endNode, endOffset);
    } catch (e) {
      console.error("error setting end of range:", e);
      return;
    }
    const content = range.extractContents();
    const mark = document.createElement("mark");
    mark.append(content);
    mark.classList.add("highlight");
    mark.setAttribute(
      "style",
      `background-color: #EAEDFF;
              border-radius: 5px;
              border: 1px solid white;
              // user-select: none;`
    );
    mark.setAttribute(
      "onMouseOver",
      `this.style.backgroundColor='#C1BED9';
              this.style.cursor="pointer";
              event.stopPropagation();`
    );
    mark.setAttribute(
      "onMouseOut",
      `this.style.backgroundColor='#C1BED9';
              this.style.cursor="default";`
    );
    mark.onclick = (e) => {
      e.stopPropagation();
      // @ts-ignore
      if (window.getSelection()?.toString().length > 0) return;
      setSelectedHighlight(highlight);
      onOpen();
    };
    range.insertNode(mark);
  };

  const drawAllHighlights = (highlightsByElems: Map<string, Highlight[]>) => {
    console.log("zzz 2", highlightsByElems);

    // go over the grouped highlights and add them
    highlightsByElems.forEach((hs, elemId) => {
      const parent = document.getElementById(elemId);
      if (parent) {
        hs.forEach((highlight) => {
          addHighlight(parent, highlight);
        });
      }
    });
  };

  useEffect(() => {
    if (show && highlights && toolBoxData.length > 0)
      drawAllHighlights(highlights);
  }, [highlights, toolBoxData]);

  const removeHighlights = () => {
    while (document.querySelectorAll("mark").length > 0) {
      const targets = document.querySelectorAll("mark");
      // @ts-ignore
      for (let target of targets) {
        target.outerHTML = target.innerHTML;
      }
    }
  };

  useEffect(() => {
    if (!show) {
      removeHighlights();
    }
  }, [show]);

  useEffect(() => {
    // unmount
    return () => {
      removeHighlights();
    };
  }, []);

  const highlightBg = useColorModeValue("whitesmoke", "gray.700");

  return (
    <>
      <HighlightPopover
        onSelectStart={startSelection}
        onSelectEnd={endSelection}
        moduleId={moduleId}
        onHighlight={() => {
          toolBoxRefetch();
        }}
      />
      <AlertDialog
        leastDestructiveRef={alertDialogRef}
        isOpen={isOpen}
        onClose={() => {
          setSelectedHighlight(null);
          onClose();
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Remove Highlight</AlertDialogHeader>
            <AlertDialogBody>
              <Text>Would you like to remove this highlight?</Text>
              <Tooltip label={selectedHighlight?.text} hasArrow>
                <Center>
                  <Text
                    noOfLines={2}
                    fontStyle="italic"
                    bgColor={highlightBg}
                    m={2}
                    py={1}
                    px={2}
                    rounded="md"
                  >
                    {selectedHighlight?.text}
                  </Text>
                </Center>
              </Tooltip>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={alertDialogRef}
                onClick={onClose}
                isDisabled={removeHighlightMutation.isLoading}
              >
                Cancel
              </Button>
              <Button
                ml={3}
                colorScheme="red"
                isLoading={removeHighlightMutation.isLoading}
                onClick={async () => {
                  await removeHighlightMutation.mutateAsync();
                  onClose();
                }}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Highlighter;
