import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { FaStop, FaVolumeUp } from "react-icons/fa";
import { cleanRawBody } from "../data/util";

type Props = {
  text: string;
  title: string;
};

const TTSButton = ({ text, title }: Props) => {
  const [speaking, setSpeaking] = useState(false);
  const MAX_TTS_LENGTH = 4000; // android tts only seems to support 4000 characters

  const createUtterance = (text: string, onEnd?: () => void) => {
    const newUtt = new SpeechSynthesisUtterance();
    newUtt.lang = "en-GB";
    newUtt.rate = 0.9;
    newUtt.text = text;
    if (onEnd) newUtt.onend = onEnd;
    return newUtt;
  };

  const utterances = useMemo(() => {
    let remaining = cleanRawBody(text, false, true);
    let utteranceList = [createUtterance(title)];
    while (remaining && remaining.length > 0) {
      const nextRemaining = remaining.substring(MAX_TTS_LENGTH);
      const newUtt = createUtterance(
        remaining.substring(0, MAX_TTS_LENGTH),
        !nextRemaining || nextRemaining.length < 1
          ? () => setSpeaking(false)
          : undefined
      );
      utteranceList.push(newUtt);
      remaining = nextRemaining;
    }
    return utteranceList;
  }, [text]);

  const speak = () => {
    setSpeaking(true);
    utterances.forEach((utt) => {
      speechSynthesis.speak(utt);
    });
    onClose();
  };

  // stop speech on unmount
  useEffect(() => {
    return () => {
      speechSynthesis.cancel();
    };
  }, []);

  const handleClick = () => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
      setSpeaking(false);
    } else {
      onOpen();
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="Read Text Aloud"
        icon={speaking ? <FaStop /> : <FaVolumeUp />}
        onClick={handleClick}
      ></IconButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Read Text Aloud</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Do you want to read the text aloud</ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <Button onClick={onClose}>Cancel</Button>
              <Button colorScheme="blue" onClick={speak}>
                Read Aloud
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TTSButton;
