import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../assets/complete.json";

type Props = {
  title: string;
  subtitle: string;
  buttonText?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
};

const CompleteNotification = ({
  title,
  subtitle,
  buttonText = "Complete",
  isOpen,
  onClose,
  onConfirm,
}: Props) => {
  const headingColour = useColorModeValue("blue.600", "blue.400");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={10} textAlign="center">
          <Heading mb={3} size="lg" color={headingColour}>
            {title}
          </Heading>
          <Heading size="sm" color={headingColour}>
            {subtitle}
          </Heading>
          <Box mt={5}>
            <Lottie
              options={{ loop: false, autoplay: true, animationData }}
              height={175}
              width={175}
            ></Lottie>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            w="full"
            onClick={() => {
              onClose();
              onConfirm?.();
            }}
            py={7}
            pt={10}
            pb={10}
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CompleteNotification;
